<template>
  <div class="AddSales">
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      v-if="tableVisible"
      :title="title"
      width="50%"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="销售姓名" prop="fullname">
            <el-input
              v-model="formData.fullname"
              placeholder="请输入销售姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="销售电话" prop="mobile">
            <el-input
              v-model="formData.mobile"
              placeholder="请输入销售电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入备注"
              v-model="formData.remarks"
            >
            </el-input
          ></el-form-item>
          <el-form-item label="状态"  prop="state">
            <el-radio-group v-model="formData.state">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="tableVisible = false">返回</el-button>
        <el-button @click="determine" type="primary" :loading="bntloading"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
    
    <script>
export default {
  name: "AddSales",
  props: {},
  data() {
    return {
      tableVisible: false,
      bntloading: false,
      title: "",
      formData: {
        fullname: "", //	string	业务姓名
        mobile: "", //	string	手机号
        remarks: "", //	string	备注
        state: 1, //number	状态 1启用 2禁用
        id: "", //	number	销售标识 编辑时传入
      },
      options: [],
      optionsUserd: [],
      optionslogistics: [],
      rules: {
        fullname: [{ required: true, message: "请输入销售姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        state: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  created() {
  },

  methods: {
    hqinfo(e, item) {
      this.title = e;
      this.tableVisible = true;
      if (item) {
        this.formData = JSON.parse(JSON.stringify(item));
      } else {
        Object.assign(this.$data.formData, this.$options.data().formData);
      }
    },

    determine() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.bntloading = true;

          this.tool.debounce(() => {
            this.$api.SalesP.salestore(this.formData)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: this.title == "添加" ? "添加成功" : "编辑成功",
                });
                this.bntloading = false;
                this.tableVisible = false;
                this.$emit("getList", this.formData);
              })
              .catch((err) => {
                this.bntloading = false;
              });
          });
        }
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.AddSales {
  .pagination-position {
    margin-top: 20px;
  }
}
</style>
    